import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

/**
 * Radio button without using input type="radio".
 */
export const Radio = forwardRef<
	ElementRef<"span">,
	Omit<ComponentPropsWithRef<"span">, "children"> & {
		checked: boolean;
	}
>(({ className, checked, ...props }, ref) => (
	<span
		ref={ref}
		className={cn(
			"box-border flex size-5 items-center justify-center rounded-full border-0 bg-brand",
			className,
		)}
		{...props}
	>
		<span
			className={cn("relative size-[calc(100%-2px)] rounded-full bg-white", checked && "size-2")}
		/>
	</span>
));

Radio.displayName = "Radio";
