"use client";

import type { ImageLoader } from "next/image";

export const ratioImageLoader =
	(ratio: number, focus?: string | null): ImageLoader =>
	({ src, width, quality }) => {
		const urlWithProtocol = src.startsWith("http") ? src : `https:${src}`;
		const parsedUrl = new URL(urlWithProtocol);

		if (parsedUrl.host === "a.storyblok.com" && !src.endsWith(".svg")) {
			const height = ratio ? Math.round(width / ratio) : 0;
			const url = `${src}/m/${width}x${height}/filters:quality(${quality || 80})`;
			return focus ? `${url}:focal(${focus})` : url;
		}
		if (parsedUrl.host === "www.zeeman.com" && src.startsWith("/media/")) {
			const height = Math.round(width * ratio);
			return `${src}?quality=${quality}&fit=bounds&height=${height}&width=${width}&canvas=${height}:${width}`;
		}

		if (parsedUrl.host === "pim-media.zeeman.com") {
			return `${src}?w=${width}`;
		}

		return src;
	};

export default ratioImageLoader(1);
