"use client";
import type {
	CheckoutData,
	EcommerceData,
	PageType,
	PageViewEventData,
} from "@commerce-frontend/gtm-ecommerce";
import { usePathname } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import { useLocale } from "next-intl";
import { useEffect } from "react";
import { useNavigation } from "~/lib/navigation-provider";
import type { LocationProps } from "~/lib/send-gtm-event";
import {
	deviceData,
	getCategoryPathFromSession,
	getPathWithCurrentCategory,
	locationData,
	locationDataFromPathname,
	userData,
} from "~/lib/send-gtm-event";
import { GtmOnceOffEvent } from "./GTMOnceOffEvent";

export type Props<T extends PageType> = Omit<
	LocationProps<T>,
	"locale" | "pageType" | "pageSubtype"
> &
	Partial<
		Pick<LocationProps<T>, "pageType" | "pageSubtype"> & {
			ecommerce?: EcommerceData;
			checkout?: CheckoutData;
		}
	>;

export const GtmPageLoadEvent = <T extends PageType>({
	ecommerce,
	checkout,
	pageType,
	pageSubtype,
	categoryId,
	...props
}: Props<T>) => {
	const locale = useLocale() as Locale;
	const pathname = usePathname();
	const { previousUrl } = useNavigation();
	const sessionCategoryPath = getCategoryPathFromSession();
	const pathWithCurrentCategoryId = getPathWithCurrentCategory(categoryId, sessionCategoryPath);

	useEffect(() => {
		if (typeof window !== "undefined") {
			sessionStorage.setItem("categoryPath", JSON.stringify(pathWithCurrentCategoryId));
		}
	}, [pathWithCurrentCategoryId]);

	return (
		<GtmOnceOffEvent<PageViewEventData>
			makeEvent={() => ({
				event: "pageview",
				device: deviceData(),
				user: userData(),
				location:
					pageType && pageSubtype
						? locationData({
								locale,
								pageType,
								pageSubtype,
								previousUrl,
								categoryId,
								...props,
							})
						: locationDataFromPathname({
								pathname,
								locale,
								previousUrl,
								categoryId,
								...props,
							}),
				timestamp: Date.now(),
				ecommerce: ecommerce || null,
			})}
		/>
	);
};
