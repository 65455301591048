import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

export const ListSection = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("flex flex-col bg-white px-4", className)} {...props}>
				{children}
			</div>
		);
	},
);

ListSection.displayName = "ListSection";

export const ListSectionHeader = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn(
					"sticky top-0 w-full bg-white py-[0.875rem] text-base text-gray-700",
					className,
				)}
				{...props}
			>
				{children}
			</div>
		);
	},
);

ListSectionHeader.displayName = "ListSectionHeader";

export const ListSectionContent = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("flex flex-col gap-1", className)} {...props}>
				<div className="pb-4 flex flex-col gap-xs">{children}</div>
			</div>
		);
	},
);

ListSectionContent.displayName = "ListSectionContent";
