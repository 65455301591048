"use client";
import { Button } from "@commerce-frontend/ui/base/Button";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from "@commerce-frontend/ui/components/Dialog/Dialog";
import { XIcon } from "lucide-react";
import { useTranslations } from "next-intl";
import { SortFilterControls, type Props as SortFilterControlsProps } from "./SortFilterControls";

type Props = SortFilterControlsProps & {
	className?: string;
};

export const SortFilterDialog = ({ className, ...controlProps }: Props) => {
	const t = useTranslations("SortFilterDialog");
	// const sortT = useTranslations("SortSelect");

	return (
		<>
			{/* <div className="flex gap-4">
				{filterState.sort !== "popularity" ? (
					<FilterTag
						paramName="sort"
						paramValue={filterState.sort}
						onClick={() => {
							setFilterState({
								...filterState,
								sort: "popularity",
							});
						}}
					>
						<span>
							<span className="font-semibold">{sortT("sort-on")}:</span> {sortT(filterState.sort)}
						</span>
					</FilterTag>
				) : null}
				{facets
					?.filter(
						(facet) =>
							facet.__typename !== "RangeFacet" && facet.options.some((option) => option.selected),
					)
					.map((facet) => (
						<Fragment key={facet.key}>
							{facet.__typename !== "RangeFacet" &&
								facet.options
									.filter((option) => option.selected)
									.map((option) => (
										<FilterTag
											key={`${facet.key}-${option.key}`}
											paramName={facet.key}
											paramValue={option.key}
											onClick={() => toggleFacet(facet.key, option.key)}
										>
											<span>
												<span className="font-semibold">{facet.label}:</span> {option.label}
											</span>
										</FilterTag>
									))}
						</Fragment>
					))
					.filter(Boolean)}
			</div> */}

			<Dialog>
				<DialogTrigger asChild>
					<Button variant="secondary" aria-label={t("open-menu")} className={className}>
						<Icon icon="settings-04" className="size-5" />
						{t("button-label")}
					</Button>
				</DialogTrigger>

				<DialogContent
					onOpenAutoFocus={(e) => {
						// prevent focus to the close button
						e.preventDefault();
					}}
					className="flex flex-col gap-0"
					aria-label={t("accessibility-name")}
					side="responsive"
				>
					<DialogTitle>{t("accessibility-name")}</DialogTitle>
					<div className="flex flex-row px-3xl pt-3xl">
						<div className="shadow-xs flex size-10 items-center justify-center rounded-md border border-gray-200">
							<Icon icon="settings-04" className="size-5 bg-gray-700" />
						</div>
						<div className="pl-4">
							<h4 className="text-xl font-semibold">{t("title")}</h4>
							<span className="text-sm text-gray-600">{t("subtitle")}</span>
						</div>
						<DialogClose className="ml-auto self-start" asChild>
							<Button
								className="focus:ring-accent-level-2 relative top-[-10px] size-11 rounded-xs text-gray-400 hover:bg-gray-50 hover:text-gray-500 focus:bg-gray-50"
								icon={<XIcon />}
								variant="link"
								aria-label={t("close-menu")}
							/>
						</DialogClose>
					</div>

					<SortFilterControls {...controlProps} />
				</DialogContent>
			</Dialog>
		</>
	);
};
