"use client";
import { useTranslations } from "next-intl";

import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import {
	ListSection,
	ListSectionContent,
	ListSectionHeader,
} from "@commerce-frontend/ui/components/ListSection/ListSection";
import { useEffect, useRef, useState } from "react";
import type { FilterState } from "~/lib/filter-state";
import { SortOption, type Props as SortOptionProps } from "./SortOption";

export const sortOptions = ["popularity", "priceAscending", "priceDescending"] as const;

type Props = {
	filterState: FilterState;
	className?: string;
	collapsed?: boolean;
	onToggle?: () => void;
	// onSelect?: (selected: boolean, item: FacetValueFragmentFragment) => void;
	onSelect?: SortOptionProps["onSelect"];
};

/**
 * Dropdown to select different sorting options
 * Uses optimistic UI to update the visual state while loading the new product results
 * @todo Get sortOptions from backend/query instead of hardcoded
 */
export const SortSelect = ({ filterState, onToggle, className, collapsed, onSelect }: Props) => {
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);
	const t = useTranslations("SortSelect");

	useEffect(() => {
		const clientHeight = contentRef.current?.clientHeight;
		if (clientHeight && clientHeight > 0) {
			setContentHeight((value) => value ?? clientHeight);
		}
	}, [collapsed, setContentHeight]);

	return (
		<ListSection className={className}>
			<ListSectionHeader>
				<button onClick={onToggle} className="flex w-full items-center text-left select-none">
					<div className="flex w-full items-center text-left select-none">
						<span className="grow">{t("sort-on")}</span>
						<Icon
							icon={collapsed ? "chevron-down" : "chevron-up"}
							className="ml-2 mr-lg bg-brand-500"
							size="sm"
						/>
					</div>
				</button>
			</ListSectionHeader>

			<ListSectionContent
				ref={contentRef}
				className="overflow-hidden transition-[max-height] duration-200"
				style={{
					maxHeight: collapsed ? 0 : contentHeight,
				}}
			>
				{sortOptions.map((item) => (
					<SortOption
						key={item}
						item={item}
						selected={filterState.sort === item}
						className="relative flex flex-row rounded-xs bg-gray-50 p-xl align-middle transition-all hover:bg-gray-100 focus:bg-gray-100 active:bg-gray-100"
						onSelect={onSelect}
					/>
				))}
			</ListSectionContent>
		</ListSection>
	);
};
