import type { ProductSortOrder } from "@commerce-frontend/types";
import { Radio } from "@commerce-frontend/ui/base/Radio/Radio";
import {
	ListItem,
	ListItemContent,
	ListItemIcon,
	ListItemLabel,
} from "@commerce-frontend/ui/components/ListItem/ListItem";
import { useTranslations } from "next-intl";
import type { sortOptions } from "./SortSelect";

export type Props = {
	item: (typeof sortOptions)[number];
	className?: string;
	selected: boolean;
	onSelect?: (action: ProductSortOrder) => void;
};

/**
 * Individual SortOption for the SortFilterDialog component
 * Uses a Link component with onClick logic to handle optimistic non-blocking updates of the URL
 */
export const SortOption = ({ item, selected, onSelect }: Props) => {
	const t = useTranslations("SortSelect");

	return (
		<ListItem
			target="_self"
			rel="nofollow"
			onClick={() => {
				onSelect?.(item);
			}}
		>
			<ListItemContent>
				<ListItemLabel>{t(item)}</ListItemLabel>
			</ListItemContent>
			<ListItemIcon>
				<Radio checked={selected} />
			</ListItemIcon>
		</ListItem>
	);
};
