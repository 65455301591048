"use client";
import type { ProductSortOrder } from "@commerce-frontend/types";
import { type FacetFragmentFragment, graphql } from "@commerce-frontend/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { DialogClose } from "@commerce-frontend/ui/components/Dialog/Dialog";
import { useClientGqlFetcher } from "@labdigital/graphql-fetcher";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStoreContext } from "~/hooks/useStoreContext";
import type {
	FilterState,
	FilterValue,
	OptionsFacetValue,
	ToggleFacetValue,
} from "~/lib/filter-state";
import { makeProductSearchArgs } from "~/lib/helpers/product-search";
import { useStoreConfig } from "~/lib/store-config/context";
import { useSafeServerLoading } from "~/lib/useSafeServerLoading";
import { FacetSelect } from "../FacetSelect/FacetSelect";
import { FacetSelectSkeleton } from "../FacetSelect/FacetSelectSkeleton";
import { SortSelect } from "../SortSelect/SortSelect";

const GetProductCount = graphql(/* GraphQL */ `
	query GetProductCount(
		$storeContext: StoreContextInput!
		$sort: ProductSortOrder!
		$filters: [FacetFilterInput!]
		$searchTerm: String
		$categoryPageId: String
	) {
		productSearch(
			storeContext: $storeContext
			sort: $sort
			filters: $filters
			categoryPageId: $categoryPageId
			searchTerm: $searchTerm
		) {
			total
		}
	}
`);

export const sortOptions = ["popularity", "priceAscending", "priceDescending"] as const;

export type Props = {
	filterState: FilterState;
	facets?: FacetFragmentFragment[];
	resultCount?: number;
	categoryPageId?: string;
};

const sortSectionKey = "sortSection";

export const SortFilterControls = (props: Props) => {
	const t = useTranslations("SortFilterDialog");
	const gqlClientFetch = useClientGqlFetcher();
	const storeContext = useStoreContext();
	const storeConfig = useStoreConfig();
	// const sortT = useTranslations("SortSelect");
	const [filterState, setFilterState] = useState(props.filterState);
	const [collapsedSections, setCollapsedSections] = useState<string[]>([]);
	const pathname = usePathname();

	const targetHref = useMemo(() => {
		const params = new URLSearchParams();
		params.set("sort", filterState.sort);

		if (filterState.searchTerm) {
			params.set("q", filterState.searchTerm);
		}

		for (const key of Object.keys(filterState.filters)) {
			const filter = filterState.filters[key];
			if (filter.type === "range") {
				continue;
			}

			if (filter.value && Array.isArray(filter.value)) {
				for (const value of filter.value) {
					params.append(key, value);
				}
			}
		}

		params.sort();

		const paramString = params.toString();
		return pathname + (paramString.length === 0 ? "" : `?${paramString}`);
	}, [filterState, pathname]);

	const { data } = useSafeServerLoading(
		useQuery({
			// initialData: { total: props.resultCount },
			queryKey: [
				"facet-result-count",
				storeContext.locale,
				storeConfig,
				targetHref,
				props.categoryPageId,
			],
			queryFn: () =>
				gqlClientFetch(
					GetProductCount,
					makeProductSearchArgs(
						storeContext.locale,
						storeConfig,
						filterState,
						props.categoryPageId,
					),
				).then((result) => result.data?.productSearch),
		}),
	);

	const reset = useMemo(
		() => () => {
			setFilterState(props.filterState);
		},
		[props.filterState, setFilterState],
	);

	const clearFilters = useMemo(
		() => () => {
			setFilterState((state) => ({
				...state,
				sort: sortOptions[0],
				filters: {},
			}));
		},
		[setFilterState],
	);

	// reset the state whenever the props are updated
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(reset, [props.facets, props.filterState]);

	const toggleSortSection = useCallback(() => {
		setCollapsedSections((state) =>
			state.includes(sortSectionKey)
				? state.filter((key) => key !== sortSectionKey)
				: [...state, sortSectionKey],
		);
	}, [setCollapsedSections]);

	const toggleFacetSection = useCallback(
		(facet: FacetFragmentFragment) => {
			setCollapsedSections((state) =>
				state.includes(facet.key)
					? state.filter((key) => key !== facet.key)
					: [...state, facet.key],
			);
		},
		[setCollapsedSections],
	);

	const onSortChange = useMemo(
		() => (sort: ProductSortOrder) => {
			setFilterState((state) => ({
				...state,
				sort,
			}));
		},
		[setFilterState],
	);

	const onFacetChange = useMemo(
		() => (toggledKey: string, facet: FacetFragmentFragment) => {
			setFilterState((state) => {
				const filter = state.filters[facet.key] as FilterValue | undefined;

				if (filter && filter.type === "range") {
					return state;
				}

				const newFilter: FilterValue =
					facet.__typename === "OptionsFacet"
						? {
								type: "options",
								value: [],
							}
						: {
								type: "toggle",
								value: false,
							};

				if (filter && filter.type === newFilter.type) {
					newFilter.value = filter.value;
				}

				if (newFilter.type === "options") {
					newFilter.value = newFilter.value.includes(toggledKey)
						? newFilter.value.filter((item) => item !== toggledKey)
						: [...newFilter.value, toggledKey];
				} else if (newFilter.type === "toggle") {
					newFilter.value = !newFilter.value;
				}

				return {
					...state,
					filters: {
						...state.filters,
						[facet.key]: newFilter,
					},
				};
			});
		},
		[],
	);

	return (
		<>
			<div className="custom-scrollbar flex w-full grow flex-col overflow-y-scroll">
				<SortSelect
					filterState={filterState}
					onToggle={toggleSortSection}
					collapsed={collapsedSections.includes(sortSectionKey)}
					className="border-b-4 border-b-gray-50 last:border-b-0"
					onSelect={onSortChange}
				/>
				{props.facets ? (
					props.facets?.map((facet) => {
						const filterValue = filterState.filters[facet.key];
						if (facet.__typename === "RangeFacet" || filterValue?.type === "range") {
							return null;
						}

						const value: OptionsFacetValue | ToggleFacetValue =
							filterValue ??
							(facet.__typename === "OptionsFacet"
								? {
										type: "options",
										value: [],
									}
								: {
										type: "toggle",
										value: false,
									});

						return (
							<FacetSelect
								key={facet.key}
								facet={facet}
								value={value}
								onToggle={toggleFacetSection}
								collapsed={collapsedSections.includes(facet.key)}
								onChange={onFacetChange}
							/>
						);
					})
				) : (
					<>
						<FacetSelectSkeleton className="border-b-4 border-b-gray-50 last:border-b-0" />
						<FacetSelectSkeleton className="border-b-4 border-b-gray-50 last:border-b-0" />
					</>
				)}
			</div>

			<div className="flex flex-col-reverse justify-between w-full gap-md border-t border-gray-300 p-xl xxs:flex-row xxs:gap-xl">
				<Button variant="tertiaryColor" onClick={clearFilters}>
					{t("reset-filter")}
				</Button>
				<DialogClose asChild>
					<Button variant="primary" className="grow" href={targetHref} rel="nofollow">
						{t("show-results", { count: data?.total })}
					</Button>
				</DialogClose>
			</div>
		</>
	);
};
