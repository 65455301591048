"use client";
import type { EventData } from "@commerce-frontend/gtm-ecommerce";
import { useEffect, useRef } from "react";
import { type DeepPromise, sendGTMEvent } from "~/lib/send-gtm-event";

export type Props<T extends EventData> = {
	makeEvent: () => DeepPromise<T>;
};

export const GtmOnceOffEvent = <T extends EventData>({ makeEvent }: Props<T>) => {
	// This hook is used to prevent the event from being sent multiple times
	const didRun = useRef(false);

	useEffect(() => {
		if (!didRun.current) {
			didRun.current = true;
			sendGTMEvent(makeEvent());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
