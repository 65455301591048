import { Skeleton } from "@commerce-frontend/ui/base/Skeleton/Skeleton";
import {
	ListSection,
	ListSectionContent,
	ListSectionHeader,
} from "@commerce-frontend/ui/components/ListSection/ListSection";

export type Props = {
	className?: string;
};

export const FacetSelectSkeleton = ({ className }: Props) => {
	return (
		<ListSection className={className}>
			<ListSectionHeader>
				<div className="flex w-full items-center text-left">
					<Skeleton type="text" textLines={1} />
				</div>
			</ListSectionHeader>

			<ListSectionContent>
				<Skeleton type="rectangle" className="w-full h-16 mb-xs" />
				<Skeleton type="rectangle" className="w-full h-16 mb-xs" />
				<Skeleton type="rectangle" className="w-full h-16 mb-xs" />
				<Skeleton type="rectangle" className="w-full h-16 mb-xs" />
			</ListSectionContent>
		</ListSection>
	);
};
