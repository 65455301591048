import { range } from "@labdigital/toolkit";
import type { ReactNode } from "react";
import { cn } from "../../helpers/styles";

type Props = {
	type?: "text" | "rectangle" | "image";
	textLines?: number;
	className?: string;
	children?: ReactNode;
};

const getStatusStyle = (type: Props["type"]) => {
	switch (type) {
		case "text":
			return "grid w-full gap-2 py-2";

		case "image":
			return "flex w-full items-center justify-center bg-gray-300";

		default:
			return "w-full bg-gray-300";
	}
};

export const Skeleton = ({ type = "rectangle", textLines = 3, className, children }: Props) => (
	<div role="status" className={cn("animate-pulse", getStatusStyle(type), className)}>
		{type === "text"
			? range({ start: 0, end: Math.floor(textLines) }).map((i) => (
					<div
						key={i}
						className={cn("h-4 rounded bg-gray-200", textWidths[i % textWidths.length])}
					/>
				))
			: null}

		{type === "image" ? (
			<svg
				className="size-10 text-gray-200"
				aria-hidden="true"
				xmlns="http://www.w3.org/2000/svg"
				fill="currentColor"
				viewBox="0 0 20 18"
			>
				<path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
			</svg>
		) : null}

		{type === "rectangle" ? children : null}

		<span className="sr-only">Loading...</span>
	</div>
);

const textWidths = [
	"max-w-[60%]",
	"max-w-[100%]",
	"max-w-[80%]",
	"max-w-[70%]",
	"max-w-[90%]",
	"max-w-[50%]",
];
