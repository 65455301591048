import type { FilterData } from "@commerce-frontend/gtm-ecommerce";
import type { FilterState } from "~/lib/filter-state";

export const filterData = (filterState: FilterState, resultCount: number): FilterData => {
	const sort = filterState.sort;
	const filters = Object.entries(filterState.filters).map(([key, filter]) => {
		return {
			filter_category: key,
			filter_name: filter.value,
		};
	});

	return {
		product_count: resultCount,
		filter_selection: filters,
		sort_selection: [
			{
				sort_name: sort,
			},
		],
	};
};
