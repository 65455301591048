import { type FacetFragmentFragment, graphql } from "@commerce-frontend/types";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import {
	ListSection,
	ListSectionContent,
	ListSectionHeader,
} from "@commerce-frontend/ui/components/ListSection/ListSection";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import type { OptionsFacetValue, ToggleFacetValue } from "~/lib/filter-state";
import { FacetOption } from "./FacetOption";

graphql(/* GraphQL */ `
	fragment RangeFacetFragment on RangeFacet {
		__typename
		key
		label
		min
		max
		selectedMin
		selectedMax
	}

	fragment OptionsFacetFragment on OptionsFacet {
		__typename
		key
		label
		options {
			...FacetValueFragment
		}
	}

	fragment ToggleFacetFragment on ToggleFacet {
		__typename
		key
		label
		options {
			...FacetValueFragment
		}
	}
`);

graphql(/* GraphQL */ `
	fragment FacetFragment on Facet {
		...RangeFacetFragment
		...OptionsFacetFragment
		...ToggleFacetFragment
	}
`);

export type Props = {
	facet: FacetFragmentFragment;
	className?: string;
	collapsed?: boolean;
	value: OptionsFacetValue | ToggleFacetValue;
	onToggle?: (facet: FacetFragmentFragment) => void;
	onChange?: (option: string, facet: FacetFragmentFragment) => void;
};

export const FacetSelect = ({ className, value, onChange, onToggle, collapsed, facet }: Props) => {
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);

	useEffect(() => {
		const clientHeight = contentRef.current?.clientHeight;
		if (clientHeight && clientHeight > 0) {
			setContentHeight((value) => value ?? clientHeight);
		}
	}, [collapsed, setContentHeight]);

	const onHeaderClick = useCallback(() => {
		onToggle?.(facet);
	}, [onToggle, facet]);

	if (facet.__typename === "RangeFacet") return null; // We don't support range facets for now

	// render out using filter state
	const selectedOptions = facet.options.filter((option) =>
		value.type === "options" ? value.value.includes(option.key) : !!value.value,
	);
	const unselectedOptions = facet.options.filter((option) =>
		value.type === "options" ? !value.value.includes(option.key) : !value.value,
	);

	return (
		<ListSection className={cn("border-b-4 border-b-gray-50 last:border-b-0", className)}>
			<ListSectionHeader>
				<button onClick={onHeaderClick} className="flex w-full items-center text-left select-none">
					<div className="flex w-full items-center text-left select-none">
						<span className="grow">{facet.label}</span>
						<Icon
							icon={collapsed ? "chevron-down" : "chevron-up"}
							className="ml-2 mr-lg bg-brand-500"
							size="sm"
						/>
					</div>
				</button>
			</ListSectionHeader>

			<ListSectionContent
				ref={contentRef}
				className="overflow-hidden transition-[max-height] duration-200"
				style={{
					maxHeight: collapsed ? 0 : contentHeight,
				}}
			>
				{selectedOptions.map((option) => {
					return (
						<FacetOption
							key={`${facet.key}-${option.key}`}
							item={option}
							selected
							onSelect={() => {
								onChange?.(option.key, facet);
							}}
						/>
					);
				})}
				{unselectedOptions.map((option) => {
					return (
						<FacetOption
							key={`${facet.key}-${option.key}`}
							item={option}
							onSelect={() => {
								onChange?.(option.key, facet);
							}}
						/>
					);
				})}
			</ListSectionContent>
		</ListSection>
	);
};
