import { Check as Icon } from "lucide-react";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";
import { cn } from "../../helpers/styles";

type Props = ComponentPropsWithoutRef<"span"> & { checked: boolean; disabled?: boolean };

// TODO: Make this more reusable using children instead of checked state
export const Check = forwardRef<ElementRef<"span">, Props>(
	({ checked, disabled, className }, ref) => (
		<span
			ref={ref}
			className={cn(
				"flex h-6 w-6 shrink-0 items-center justify-center rounded border-2 border-brand disabled:cursor-not-allowed disabled:opacity-50",
				checked && "bg-brand text-white",
				disabled && "border-gray-300 bg-gray-50",
				className,
			)}
		>
			{checked && <Icon />}
		</span>
	),
);

Check.displayName = "Check";
