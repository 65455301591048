"use client";

import { type FacetValueFragmentFragment, graphql } from "@commerce-frontend/types";
import { Check } from "@commerce-frontend/ui/base/Check/Check";
import {
	ListItem,
	ListItemContent,
	ListItemIcon,
	ListItemLabel,
} from "@commerce-frontend/ui/components/ListItem/ListItem";

graphql(/* GraphQL */ `
	fragment FacetValueFragment on FacetValue {
		key
		label
		count
	}
`);

export type Props = {
	item: FacetValueFragmentFragment;
	selected?: boolean;
	onSelect?: () => void;
};

/**
 * Individual FacetLink for the SortFilterDialog component
 * Uses a Link component with onClick logic to handle optimistic non-blocking updates of the URL
 */
export const FacetOption = ({ item, selected, onSelect }: Props) => (
	<ListItem target="_self" rel="nofollow" onClick={onSelect}>
		<ListItemContent>
			<ListItemLabel>{item.label}</ListItemLabel>
		</ListItemContent>
		<ListItemIcon className="text-sm text-gray-600">
			{item.count} <Check checked={!!selected} className="ml-2.5 size-5" />
		</ListItemIcon>
	</ListItem>
);
