import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { Icon } from "../../base/Icon/Icon";
import { cn } from "../../helpers/styles";
import { Button } from "../../primitives/Button/Button";

export const ListItem = forwardRef<
	ElementRef<typeof Button>,
	ComponentPropsWithRef<typeof Button> & VariantProps<typeof listItemVariants>
>(({ children, className, variant, disabled, ...props }, ref) => {
	return (
		<Button
			ref={ref}
			className={cn(listItemVariants({ variant, disabled }), className)}
			disabled={disabled}
			{...props}
		>
			{children}
		</Button>
	);
});

ListItem.displayName = "ListItem";

const listItemVariants = cva(
	"group flex min-h-16 w-full items-center justify-between gap-lg rounded-xs border-l-4 border-transparent p-md px-lg transition-all",
	{
		defaultVariants: {
			disabled: false,
		},
		variants: {
			variant: {
				active: "border-orange-dark-200 bg-brand-100",
			},
			disabled: {
				true: "",
				false: "bg-gray-50 hover:bg-brand-50 focus:border-orange-dark-200 focus:bg-brand-100",
			},
		},
	},
);

export const ListItemIcon = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("flex items-center justify-center", className)} {...props}>
				{children}
			</div>
		);
	},
);

ListItemIcon.displayName = "ListItemIcon";

export const ListItemContent = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn("mr-auto flex flex-col items-start text-left", className)}
				{...props}
			>
				{children}
			</div>
		);
	},
);

ListItemContent.displayName = "ListItemContent";

export const ListItemOverline = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("text-xs font-semibold text-brand-700", className)} {...props}>
				{children}
			</div>
		);
	},
);

ListItemOverline.displayName = "ListItemOverline";

export const ListItemLabel = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("font-semibold", className)} {...props}>
				{children}
			</div>
		);
	},
);

ListItemLabel.displayName = "ListItemLabel";

export const ListItemDescription = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("text-sm text-gray-600", className)} {...props}>
				{children}
			</div>
		);
	},
);

ListItemDescription.displayName = "ListItemDescription";

export const ListItemTrailingIcon = forwardRef<
	ElementRef<typeof Icon>,
	ComponentPropsWithRef<typeof Icon>
>(({ className, ...props }, ref) => {
	return <Icon ref={ref} {...props} className={cn("size-6 bg-brand", className)} />;
});
ListItemTrailingIcon.displayName = "ListItemTrailingIcon";

export const ListItemChevron = forwardRef<
	ElementRef<typeof Icon>,
	Omit<ComponentPropsWithRef<typeof Icon>, "icon">
>((props, ref) => {
	return <ListItemTrailingIcon ref={ref} {...props} icon="chevron-right" />;
});
ListItemChevron.displayName = "ListItemChevron";

export const ListItemTrailingLabel = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("text-sm text-gray-600", className)} {...props}>
				{children}
			</div>
		);
	},
);

ListItemTrailingLabel.displayName = "ListItemTrailingLabel";
