"use client";

import type { ImageLoader, ImageProps } from "next/image";
import NextImage from "next/image";
import { type JSX, useCallback } from "react";
import { ratioImageLoader } from "../../../image-loader";

export const imagePlaceholder = "/_static/assets/placeholder.svg";
export const cardImagePlaceholder = "/_static/assets/card-placeholder.png";
export const trustedShopsLogo = "/_static/icons/trustedshops.svg";

export type Props = {
	focus?: string | null;
	aspectRatio?: number | null;
} & Omit<ImageProps, "loader">;

const getLoader = (
	width: Props["width"],
	height: Props["height"],
	focus: Props["focus"],
	aspectRatio: Props["aspectRatio"],
): ImageLoader => {
	const ratio = Number(aspectRatio);
	if (!Number.isNaN(ratio)) {
		return ratioImageLoader(ratio, focus);
	}

	const heightNumber = Number.parseInt(`${height}`, 10);
	const widthNumber = Number.parseInt(`${width}`, 10);

	if (!Number.isNaN(heightNumber) && heightNumber > 0) {
		if (!Number.isNaN(widthNumber) && widthNumber > 0) {
			return ratioImageLoader(widthNumber / heightNumber, focus);
		}
	}

	return ratioImageLoader(Number.NaN, focus);
};

// https://github.com/vercel/next.js/discussions/22050
export const Image = ({ width, height, focus, aspectRatio, ...props }: Props): JSX.Element => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const imageLoader: ImageLoader = useCallback(getLoader(width, height, focus, aspectRatio), [
		width,
		height,
		focus,
		aspectRatio,
	]);

	return (
		<NextImage
			loading={props.priority ? undefined : "lazy"}
			{...props}
			width={width}
			height={height}
			loader={imageLoader}
		/>
	);
};
