"use client";

import type {
	ProductClickEventData,
	ProductImpressionEventData,
	ProductListerEventData,
} from "@commerce-frontend/gtm-ecommerce";
import { usePathname } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import type { ProductSearchResultsFragment } from "@commerce-frontend/types";
import { ProductGrid } from "@commerce-frontend/ui/components/ProductGrid/ProductGrid";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
import { GtmOnceOffEvent } from "~/components/GoogleAnalytics/GTMOnceOffEvent";
import { Pagination } from "~/components/Pagination/Pagination";
import { ProductCard } from "~/components/ProductCard/ProductCard";
import type { FilterState } from "~/lib/filter-state";
import { productDetailHref } from "~/lib/link-builder";
import { useNavigation } from "~/lib/navigation-provider";
import {
	deviceData,
	ecommerceData,
	locationDataFromPathname,
	sendGTMEvent,
	userData,
} from "~/lib/send-gtm-event";
import { filterData } from "~/lib/send-gtm-event/filter";
import { useStoreConfig } from "~/lib/store-config/context";
import { SortFilterDialog } from "../SortFilterDialog/SortFilterDialog";

type Props = {
	productsResult: ProductSearchResultsFragment;
	filterState: FilterState;
	categoryId?: string;
	categoryPageId?: string;
	locale: Locale;
	title: string;
};

export const ProductListingView = ({
	productsResult,
	filterState,
	categoryId,
	categoryPageId,
	locale,
	title,
}: Props) => {
	const t = useTranslations("ProductListing");
	const pathname = usePathname();
	const { previousUrl } = useNavigation();
	const config = useStoreConfig();

	useEffect(() => {
		sendGTMEvent<ProductImpressionEventData>({
			event: "product_impression",
			timestamp: Date.now(),
			device: deviceData(),
			user: userData(),
			location: locationDataFromPathname({
				locale,
				pathname,
				categoryId,
				previousUrl,
				pageType: "category",
				pageSubtype: "productlister",
			}),
			ecommerce: ecommerceData(productsResult.results.map((product) => ({ product }))),
		});
	}, [productsResult, title, pathname, locale]); // eslint-disable-line react-hooks/exhaustive-deps

	const trackProductClick = (product: ProductSearchResultsFragment["results"][number]) => {
		sendGTMEvent<ProductClickEventData>({
			event: "product_click",
			timestamp: Date.now(),
			click_url: `${config.metadata.productionUrl}${locale.toLocaleLowerCase()}/product/${product.slug}`,
			device: deviceData(),
			user: userData(),
			location: locationDataFromPathname({
				locale,
				pathname,
				categoryId,
				previousUrl,
				pageType: "category",
				pageSubtype: "productlister",
			}),
			ecommerce: ecommerceData([
				{
					product,
				},
			]),
		});
	};

	return (
		<>
			<GtmOnceOffEvent<ProductListerEventData>
				makeEvent={() => ({
					event: "product_lister",
					user: userData(),
					device: deviceData(),
					location: locationDataFromPathname({
						pathname,
						locale,
						categoryId,
						previousUrl,
						pageType: "category",
						pageSubtype: "productlister",
					}),
					timestamp: Date.now(),
					filter: filterData(filterState, productsResult.total),
				})}
			/>

			{productsResult ? (
				<div className="relative">
					<SortFilterDialog
						filterState={filterState}
						facets={productsResult.facets ?? undefined}
						resultCount={productsResult.total}
						categoryPageId={categoryPageId}
						className="float-left mb-6 w-full md:float-right md:mb-0 md:w-auto"
					/>

					<span className="text-sm font-semibold md:absolute md:bottom-0">
						<span>{t("results-items", { total: productsResult.total })}</span>{" "}
						<span className="font-normal">{t("results-found")}</span>
					</span>
				</div>
			) : null}

			{productsResult.results.length === 0 ? (
				<div className="mt-4 flex flex-col items-center justify-start gap-4 bg-gray-300 p-8 md:mt-6 md:px-14 md:py-12 lg:flex-row lg:p-16">
					<div className="flex flex-col items-center md:mb-3 lg:items-start">
						<h2 className="mb-2 md:mb-4">{t("no-results")}</h2>

						<p className="text-center">{t("no-results-matching-criteria")}</p>
					</div>
				</div>
			) : (
				<div className="group flex flex-col gap-y-7xl">
					<ProductGrid className="group-has-[[data-pending]]:animate-pulse">
						{productsResult.results.map((product) => (
							<Link
								// Changed from id to sku to avoid duplicate key errors
								key={product.primaryVariant.sku}
								href={productDetailHref(product.slug)}
								onClick={() => trackProductClick(product)}
							>
								<ProductCard product={product} locale={locale} />
							</Link>
						))}
					</ProductGrid>

					{productsResult.totalPages > 1 && (
						<Pagination
							locale={locale}
							currentPage={filterState.page}
							totalPages={productsResult.totalPages}
						/>
					)}
				</div>
			)}
		</>
	);
};
