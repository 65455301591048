import { normalizeContext } from "@commerce-frontend/i18n/helpers";
import type { GetProductsQueryVariables } from "@commerce-frontend/types";
import type { FilterState } from "../filter-state";
import type { StoreConfig } from "../store-config/context";

export const makeProductSearchArgs = (
	locale: string,
	storeConfig: StoreConfig,
	filterState: FilterState,
	categoryPageId?: string,
): GetProductsQueryVariables => ({
	storeContext: normalizeContext({
		currency: storeConfig.currency,
		locale: locale,
		storeKey: storeConfig.storeKey,
	}),

	searchTerm: filterState.searchTerm,

	filters: Object.keys(filterState.filters)
		// TODO: filter by enabled facets?
		.map((key) => ({ key, ...filterState.filters[key] }))
		.map(({ type, key, value }) => ({
			key,
			selections: type === "options" ? value : undefined,
			max: type === "range" ? value.max : undefined,
			min: type === "range" ? value.min : undefined,
			toggle: type === "toggle" ? value : undefined,
		})),
	categoryPageId,
	page: filterState.page,
	pageSize: filterState.pageSize,
	sort: filterState.sort,
});
